<template>
  <div class="cost">
    <Header back="返回" title="高新产品" index="首页" titleOne="科创模块" titleTwo="高新产品" beforeTitle="产品信息" />
    <div class="content">
      <h1>产品信息</h1>
      <el-form
        :rules="formRule"
        ref="formRule"
        :model="form"
        :label-position="labelPosition"
        label-width="80px"
      >
        <el-form-item label="企业名称" prop="company_name">
          <el-autocomplete
            class="inline-input"
            v-model="form.company_name"
            :fetch-suggestions="querySearchAsync"
            @select="handleSelect"
            placeholder="请输入企业名称关键字"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="技术领域" prop="technology_one">
          <el-select v-model="form.technology_one" placeholder="请选择" @change="getTechnology_two">
            <el-option v-for="item in one" :key="item.id" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="technology_two">
          <el-select v-model="form.technology_two" placeholder="请选择" @change="getTechnology_three">
            <el-option v-for="item in two" :key="item.id" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="technology_three">
          <el-select v-model="form.technology_three" placeholder="请选择">
            <el-option v-for="item in three" :key="item.id" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品(服务)名称" prop="ps_name">
          <el-input v-model="form.ps_name"></el-input>
        </el-form-item>
        <el-form-item label="PS编号">
          <el-input v-model="form.ps_code"></el-input>
        </el-form-item>
        <el-form-item label="技术来源">
          <el-select v-model="form.technology_source" placeholder="请选择">
            <el-option label="大专院校" :value="1">大专院校</el-option>
            <el-option label="地方属科研院所" :value="2">地方属科研院所</el-option>
            <el-option label="其他企业技术" :value="3">其他企业技术</el-option>
            <el-option label="引进技术本企业消化创新" :value="4">引进技术本企业消化创新</el-option>
            <el-option label="国外技术" :value="5">国外技术</el-option>
            <el-option label="企业自有技术" :value="6">企业自有技术</el-option>
            <el-option label="中央属科研院所" :value="7">中央属科研院所</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上年度销售收入(万元)">
          <el-input v-model="form.sales"></el-input>
        </el-form-item>
        <el-form-item label="证明材料" style="width: 73%">
          <el-select v-model="form.supporting_materials" multiple placeholder="请选择">
            <el-option
              v-for="item in this.supporting_materials"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否主要产品(服务)">
          <el-radio-group v-model="form.is_main">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="关键技术及主要技术指标(限400字)" style="width: 100%">
          <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="form.main_technology"></el-input>
        </el-form-item>
        <el-form-item label="与同类产品(服务)的竞争优势(限400字)" style="width: 100%">
          <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="form.advantage"></el-input>
        </el-form-item>
        <el-form-item label="知识产权获得情况及其对产品(服务)在技术上发挥的支持作用(限400字)" style="width: 100%">
          <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="form.effect"></el-input>
        </el-form-item>
      </el-form>
      <div class="foot_TJ">
        <el-button type="primary" :loading="btnLoading" @click="onSubmit" :disabled="isDisable">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      btnLoading: false,//提交按钮动画
      isDisable: false,
      labelPosition: "top",
      form: {
        company_name: "",
        technology_one: "",
        technology_two: "",
        technology_three: "",
        ps_name: "",
        ps_code: "",
        technology_source: "",
        sales: "",
        supporting_materials: [],
        is_main: "",
        main_technology: "",
        advantage: "",
        effect: "",
        com_id: "",
      },
      one: [],
      two: [],
      three: [],
      supporting_materials: [],
      formRule: {
        company_name: [
          {
            required: true,
            message: "请输入企业名称关键字",
            trigger: "blur",
          },
        ],
        technology_one: [
          {
            required: true,
            message: "请选择技术领域",
            trigger: "change",
          },
        ],
        technology_two: [
          {
            required: true,
            message: "请选择技术领域",
            trigger: "change",
          },
        ],
        technology_three: [
          {
            required: true,
            message: "请选择技术领域",
            trigger: "change",
          },
        ],
        ps_name: [
          {
            required: true,
            message: "产品(服务)名称",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    "form.technology_one": {
      handler (val, oval) {
        this.getTechnology_two(val);
      },
      deep: true,
    },
    "form.technology_two": {
      handler (val, oval) {
        this.getTechnology_three(val);
      },
      deep: true,
    },
  },
  created () {
    this.getTechnology_one();
    this.getSupporting_materials();
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  mounted () {
    if (this.meber_id) {
      this.axios
        .get("/api/ps/view", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          console.log(res.data);
          this.form = res.data;
        });
    }
  },
  methods: {
    getTechnology_one () {
      this.axios.get("/api/tech/get_first_level").then((res) => {
        this.one = res.data;
      });
    },
    getTechnology_two (id) {
      this.axios
        .get("/api/tech/get_children_list", {
          params: {
            id: id,
          },
        })
        .then((res) => {
          this.two = res.data;
        });
    },
    getTechnology_three (id) {
      this.axios
        .get("/api/tech/get_children_list", {
          params: {
            id: id,
          },
        })
        .then((res) => {
          this.three = res.data;
        });
    },
    getSupporting_materials () {
      this.form.supporting_materials = [];
      this.axios.get("/api/material/list").then((res) => {
        console.log(res);
        this.supporting_materials = res.data;
      });
    },
    querySearchAsync (queryString, cb) {
      clearTimeout(this.timeout);
      var results = [];
      if (queryString == "") {
        cb(results);
      } else {
        this.axios
          .get("/api/company/select_company", {
            params: {
              keywords: queryString,
            },
          })
          .then((res) => {
            if (res.message == "查询成功") {
              for (let i = 0; i < res.data.length; i++) {
                const element = res.data[i];
                console.log(element);
                results.push({
                  value: element.company_name,
                  id: element.id,
                });
                console.log(results);
              }
              cb(results);
            } else {
              results = [];
              cb(results);
            }
          });
      }
    },
    //点击出现搜索后点击的每一项
    handleSelect (item) {
      console.log(item);
      this.form.com_id = item.id;
      this.form.company_name = item.value;
    },
    onSubmit () {
      this.$refs.formRule.validate((vaild) => {
        this.com_id = this.form.com_id;
        if (this.form.supporting_materials) {
          this.form.supporting_materials =
            this.form.supporting_materials.join(",");
        }
        if (vaild) {
          this.btnLoading = true
          this.axios
            .post("/api/ps/store", this.form)
            .then((res) => {
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.$router.push("/ps/index");
              this.isDisable = true;
              this.btnLoading = false
            })
            .catch((res) => {
              console.log("err:", res);
              this.btnLoading = false
            });
        } else {
          this.$message.error("请输入必填项");
        }
      });
    },
  },
};
</script>

<style scoped="scoped">
::v-deep.el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}

.el-select {
  width: 100%;
}

.ImportTop span {
  color: #a6abc7;
}
</style>
